<!--Start Back To Top Button-->
<a href="javaScript:;" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a>
<!--End Back To Top Button-->
<!--Start footer-->
<footer class="footer">
	<div class="container">
		<div class="text-center"> <a href="https://www.amaravathisoftware.com/" target="blank">Copyright © 2024 Design & Develop by Amaravathi Software Innonvations Pvt Ltd</a> </div>
	</div>
</footer>
<!--End footer-->
