<header class="topbar-nav">
    <nav id="header-setting" class="navbar navbar-expand fixed-top">
        <div class="toggle-menu" (click)="toggleSidebar()">
            <i class="zmdi zmdi-menu"></i>
        </div>
        <div style="margin-left:30%">
            <div *ngIf="login_type == 0">
                <h4 style="font-size:20px;color:blue;font-family:'Times New Roman'">Admin Dashboard</h4>
            </div>
            <div *ngIf="login_type == 1">
                <h4 style="font-size:20px;color:blue;font-family:'Times New Roman'">Supervisor Dashboard</h4>
            </div>
            <div *ngIf="login_type == 2">
                <h4 style="font-size:20px;color:blue;font-family:'Times New Roman'"> Staff Dashboard</h4>
            </div>
        </div>
     

        <ul class="navbar-nav align-items-center right-nav-link ml-auto">
            
            <!-- <li class="dashboard-heading">
                <div *ngIf="login_type == 0">
                    <h4>Admin Dashboard</h4>
                </div>
                <div *ngIf="login_type == 1">
                    <h4>Supervisor Dashboard</h4>
                </div>
                <div *ngIf="login_type == 2">
                    <h4>Staff Dashboard</h4>
                </div>
            </li> -->

            <!-- Display username -->
            <li class="username">
                {{username}}
            </li>
            <!-- Dropdown for additional options -->
            <li ngbDropdown class="nav-item dropdown-lg">
                <!-- Dropdown content can be added here if needed -->
            </li>

            <!-- User profile and logout dropdown -->
            <li ngbDropdown class="nav-item">
                <a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic4"
                    ngbDropdownToggle>
                    <span class="user-profile">
                        <img src="assets/images/avatars/itdarampachodavaramlogo.png" class="img-circle" alt="user avatar">
                    </span>
                </a>
                <ul ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu-right">
                    <li ngbDropdownItem class="user-details">
                        <a href="javaScript:;">
                            <div class="media">
                                <div class="avatar">
                                    <img class="align-self-start mr-3" src="assets/images/avatars/itdarampachodavaramlogo.png" alt="user avatar">
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-2 user-title">{{username}}</h6>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li ngbDropdownItem class="log" (click)="logout()">
                        <i class="zmdi zmdi-power mr-3"></i> Logout
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</header>