import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginModel } from '../model/login.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


var LoginApi = 'https://spsnls.in:8527/nodeapp/';
// var LoginApi = 'http://localhost:1405/nodeapp/';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private currentUserSubject: BehaviorSubject<LoginModel>;   // current subject by default
  public currentUser: Observable<LoginModel>;        // Emmiting value when changes occures
  loginData: LoginModel;                    // Login Model Data

  userlogindataarr;
  submodulearr;
  private getlogintype = new BehaviorSubject(0); // Initial state is null
  viewlogintype = this.getlogintype.asObservable();
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginModel {
    return this.currentUserSubject.value;
  }

  checkmoblenumber(signInData) {
    return this.http.post<any>(LoginApi + `checkusermobilenumbermanyam`, signInData)
      .pipe(map(resdata => {
        this.currentUserSubject.next(resdata);
        localStorage.setItem('currentUser', JSON.stringify(resdata));
        localStorage.setItem('login_type', JSON.stringify(resdata.data[0].login_type));
        return resdata;
      }));
  }

  checkotpstatus(otp) {
    // return this.http.get<any>(LoginApi + `checkotpstatus` + otp)
    // .pipe(map(resdata => {
    //   return resdata;
    // }));
    console.log(otp , 50);
    return this.http.get<any>(LoginApi + `checkotpstatus/` + otp);
  }
  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('login_type');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/signin2']);
  }
  knownlogintypevalue(user) {
    this.getlogintype.next(user);
  }
}