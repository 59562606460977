import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { LoginService } from 'src/app/core/services/login.service';
import { TappalserviceService } from 'src/app/dashboard/tappal/tappalservice.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    username: any; departmentlist = [];
    login_type:any;
    constructor(public sidebarservice: SidebarService, private loginService: LoginService, private tappalservice: TappalserviceService, private router: Router) {
        this.username = localStorage.getItem("fl_nm");
        this.login_type = localStorage.getItem('login_type');
    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
        if ($("#wrapper").hasClass("nav-collapsed")) {
            // unpin sidebar when hovered
            $("#wrapper").removeClass("nav-collapsed");
            $("#sidebar-wrapper").unbind("hover");
        } else {
            $("#wrapper").addClass("nav-collapsed");
            $("#sidebar-wrapper").hover(
                function () {
                    $("#wrapper").addClass("sidebar-hovered");
                },
                function () {
                    $("#wrapper").removeClass("sidebar-hovered");
                }
            )

        }
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }


    ngOnInit() {

        /* Search Bar */
        $(document).ready(function () {
            $(".search-btn-mobile").on("click", function () {
                $(".search-bar").addClass("full-search-bar");
            });
            $(".search-arrow-back").on("click", function () {
                $(".search-bar").removeClass("full-search-bar");
            });
        });

        var officersdata = {
            'mobile': localStorage.getItem("user_phone")
        }
        // this.tappalservice.getdepartmentlist(officersdata).subscribe((resdata: any) => {

        //     console.log(resdata);
        //     if (resdata.data.length > 1) {
        //         this.departmentlist = resdata.data;
        //         console.log(this.departmentlist);
        //     } else {

        //     }
        // }, er => {
        //     console.log(er);
        // })
    }

    /**
  * Logout the user
  */
    logout() {
        this.loginService.logout();
    }

    updatedepartments(deptdata) {
        console.log(deptdata);
        localStorage.setItem("department_id", deptdata.department_id)
        localStorage.setItem("officer_id", deptdata.officer_id)
        location.reload();
    }

    gotohomedashboard() {
        this.router.navigate(['auth/homepage']);
    }
}
