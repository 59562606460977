import { Component } from '@angular/core';
import { TappalserviceService } from 'src/app/dashboard/tappal/tappalservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
//   constructor(private tappalservice: TappalserviceService) {
//     this.updatemaintable();
//     this.updatemaintable2();
//   }

//   updatemaintable() {
//     var data = {
//         'indicator': 1
//     }
//     this.tappalservice.updatemaintappaltable(data).subscribe((res: any) => { 
//       if(res.status==200){   
         
//       } 
//     }, er => {
//       console.log(er);
//     }) 
// } 
//  updatemaintable2 = function () {
//     var data = {
//         'indicator': 2
//     }
//     this.tappalservice.updatemaintappaltable(data).subscribe((res: any) => { 
//       if(res.status==200){   
         
//       } 
//     }, er => {
//       console.log(er);
//     }) 
// }
}